import { useState } from 'react';

import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';

import TrialSetupWizard from 'accruals/wizards/trial-setup/TrialSetupWizard';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';
import { selectCompany } from 'shared/state/slices/companySlice';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetTrialsByCompanyQuery } from 'shared/api/rtkq/trials';

import withAccrualPageRequiredData from 'accruals/api/hocs/withAccrualPageRequiredData';

import TrialRecordCard from '../trial/components/TrialRecordCard';
function TrialSettingsPage() {
  const trial = useSelector(selectTrial);
  const currentCompany = useSelector(selectCompany);
  const { refetch: refetchAllTrials } = useGetTrialsByCompanyQuery(
    currentCompany.trace_id,
  );

  const [editModalOpen, setEditModalOpen] = useState(false);
  const afterFormSave = () => {
    void refetchAllTrials();
    setEditModalOpen(false);
  };
  return (
    <PageContentLoadingContainer>
      <Grid spacing={3} container>
        <Grid xs={4} item>
          <TrialRecordCard onEditButtonClick={() => setEditModalOpen(true)} />
        </Grid>
      </Grid>
      {editModalOpen && (
        <TrialSetupWizard
          afterSave={afterFormSave}
          company={currentCompany}
          trial={trial}
          onClose={() => setEditModalOpen(false)}
        />
      )}
    </PageContentLoadingContainer>
  );
}

export default withAccrualPageRequiredData(TrialSettingsPage);
