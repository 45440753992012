import Typography from '@mui/material/Typography';

function uiVersionTimestamp(): Date | null {
  const timestamp = document
    .querySelector('meta[name="ui-version"]')
    ?.getAttribute('content');
  if (!timestamp) {
    return null;
  }
  const milliseconds_from_unix_ts = Number.parseInt(timestamp) * 1000;
  return new Date(milliseconds_from_unix_ts);
}

function LastCodeUpdate() {
  const timestamp = uiVersionTimestamp();
  if (!timestamp) return <>{null}</>;
  const year = timestamp.toLocaleString('default', { year: 'numeric' });
  const month = timestamp.toLocaleString('default', { month: '2-digit' });
  const day = timestamp.toLocaleString('default', { day: '2-digit' });
  const time = timestamp.toLocaleString('default', {
    hour: '2-digit',
    minute: '2-digit',
  });
  const combinedString = `Last update: ${year}-${month}-${day} ${time}`;
  if (Math.random() < 0.5) return <>{null}</>;
  return <Typography color="text.primary">{combinedString}</Typography>;
}

export default LastCodeUpdate;
